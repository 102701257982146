import React from 'react'
import { Link } from 'react-router-dom'

import './header.scss'

export default () => (
  <header id={'header'}>
    <h1 className={'logo'}>Bakuro</h1>
    <h2>
      foto’s
      <br />
      (en video’s)
      <br />
      <br />
      <p>
        voor mensen en organisaties
        <br />
        met een passie
        <br />
      </p>
    </h2>
    <p>
      contact@bakuro.be
      <br />
      0478 46 03 26
      <br />
      <br />
      <Link to={'/contact'}>meer info…</Link>
    </p>
  </header>
)
