import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './styles/app.scss'

import Header from './header'
import Contact from './contact'
import Gallery from './gallery'
import Detail from './gallery/detail'

export default () => (
  <Router>
    <Header />
    <Gallery />

    <Route path={'/image/:imageName'} exact component={Detail} />
    <Route path={'/contact'} exact component={Contact} />
  </Router>
)
